import { genericApiRequest } from './helpers';

const emptyResponse = {
  full: [],
};

async function createOutflowTransaction(outflowTransactionData) {
  const payload = {
    method: 'post',
    data: {
      ...outflowTransactionData,
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function readOutflowTransactions() {
  const method = 'get';
  const payload = {
    method,
    data: {
      outflow: {},
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function updateOutflowTransaction(outflow) {
  const payload = {
    method: 'put',
    data: {
      outflow,
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function deleteOutflowTransaction(outflowId) {
  const payload = {
    method: 'delete',
    data: {
      outflow: {
        id: outflowId,
      },
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

export {
  createOutflowTransaction,
  readOutflowTransactions,
  updateOutflowTransaction,
  deleteOutflowTransaction,
};
