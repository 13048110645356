import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../stores/UserContext';
import { OutflowContext } from '../stores/OutflowContext';

import UserProfile from '../components/UserProfile';
import ChangePage from '../components/ChangePage';
import TransactionForm from '../components/TransactionForm';
import TransactionsTable from '../components/TransactionsTable';
import TotalsMonthly from '../components/TotalsMonthly';
import saidasTransactionCodes from '../helpers/codigos_pae/saidas';

const saidasTransactionCodesValues = Object.values(saidasTransactionCodes);
const saidasTransactionCodesConcat = [].concat(...saidasTransactionCodesValues);

function OutflowPage() {
  const {
    outflowTransactions,
    outflowDescriptionSuggestions,
    createOutflowTransaction,
    updateOutflowTransaction,
    deleteOutflowTransaction,
  } = useContext(OutflowContext);
  const { userLogged } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userLogged) {
      return navigate('/saidas');
    }
    return navigate('/');
  }, [userLogged]);

  return (
    <>
      <UserProfile />
      <TotalsMonthly
        inflowTransactions={outflowTransactions}
      />
      <ChangePage />
      <TransactionForm
        transactionCodes={saidasTransactionCodesConcat}
        transactionCodeDefault=""
        updateTransactions={updateOutflowTransaction}
        descriptionSuggestions={outflowDescriptionSuggestions}
        createTransaction={createOutflowTransaction}
      />
      <TransactionsTable
        transactions={outflowTransactions}
        updateTransaction={updateOutflowTransaction}
        removeTransaction={deleteOutflowTransaction}
      />
    </>
  );
}

export default OutflowPage;
