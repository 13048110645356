import React, {
  createContext, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext();

function UserProvider({ children }) {
  const [userLogged, setUserLogged] = useState(false);
  const [appLocation, setAppLocation] = useState('inflow');

  const getUserLogged = () => {
    const imwContabUserLogged = JSON.parse(localStorage.getItem('imw-contab-user-logged'));

    if (imwContabUserLogged) {
      setUserLogged(imwContabUserLogged);
    }
  };

  const setUserData = (user) => {
    setUserLogged(user);
    localStorage.setItem('imw-contab-user-logged', JSON.stringify(user));
  };

  const logoutUser = () => {
    setUserLogged(false);
    localStorage.removeItem('imw-contab-user-logged');
  };

  const memoObjects = {
    userLogged,
    setUserData,
    logoutUser,
    appLocation,
    setAppLocation,
  };

  const memoDeps = [
    userLogged,
    setUserData,
    logoutUser,
    appLocation,
    setAppLocation,
  ];

  const contextValue = useMemo(
    () => (memoObjects),
    memoDeps,
  );

  useEffect(() => {
    getUserLogged();
  }, []);
  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext };

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
