import axios from 'axios';

const env = process.env.REACT_APP_TABLE_ENV || 'qa';
const apiUrl = `https://us-central1-pedrobritohub.cloudfunctions.net/imw_contab_${env}`;

async function genericApiRequest(payload, defaultWhenError = null) {
  try {
    const response = await axios.post(apiUrl, payload);
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.warn('------ ERROR NO RETORNO DA API ------');
    console.warn(payload);
    console.warn('------ ERROR NO RETORNO DA API ------');
    return defaultWhenError;
  }
}

export {
  apiUrl,
  genericApiRequest,
};
