import React, { useState, useContext } from 'react';

import { UserContext } from '../../stores/UserContext';
import { apiReadUser } from '../../apiClient';

import imwLogo from '../../assets/imw_logo.svg';
import './Login.css';

const defaultUserValues = {
  name: '',
  password: '',
};

function LoginPage() {
  const { setUserData } = useContext(UserContext);

  const [inLoginProcess, setInLoginProcess] = useState(false);
  const [userValues, setUserValues] = useState(defaultUserValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserValues((prevUserValues) => ({ ...prevUserValues, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    document.activeElement.blur();

    setInLoginProcess(true);

    const data = await apiReadUser(userValues);

    setUserValues(defaultUserValues);
    setInLoginProcess(false);

    if ('msg' in data) {
      const customAlert = alert;
      return customAlert(data.msg);
    }
    return setUserData(data);
  };

  return (
    <div className="login-page">
      <div className="login-container">
        {inLoginProcess && <h3>Fazendo login...</h3>}
        <h2>IMW Lançamentos</h2>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-form-label-container">
            <label htmlFor="name-login">
              <span>Nome:</span>
              <input
                id="name-login"
                type="text"
                name="name"
                value={userValues.name}
                onChange={handleInputChange}
              />
            </label>
            <label htmlFor="password-login">
              <span>Senha:</span>
              <input
                id="password-login"
                type="password"
                name="password"
                value={userValues.password}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
      <img src={imwLogo} alt="IMW logo" />
    </div>
  );
}

export default LoginPage;
