import React, {
  createContext, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  apiCreateOutflowTransaction,
  apiReadOutflowTransactions,
  apiUpdateOutflowTransaction,
  apiDeleteOutflowTransaction,
} from '../apiClient';

const OutflowContext = createContext();

function OutflowProvider({ children }) {
  const [outflowTransactions, setOutflowTransactions] = useState([]);
  const [resumeOutflowTransactions, setResumeOutflowTransactions] = useState({});
  const [outflowDescriptionSuggestions, setOutflowDescriptionSuggestions] = useState({});

  const setTransactionsContext = (response) => {
    setOutflowTransactions(response.full);
    setResumeOutflowTransactions(response.resume);
    setOutflowDescriptionSuggestions(response.descriptions);
  };

  const createOutflowTransaction = async (data) => {
    const response = await apiCreateOutflowTransaction(data);
    setTransactionsContext(response);
  };

  const readOutflowTransactions = async () => {
    const response = await apiReadOutflowTransactions();
    setTransactionsContext(response);
  };

  const updateOutflowTransaction = async (data) => {
    const response = await apiUpdateOutflowTransaction(data);
    setTransactionsContext(response);
  };

  const deleteOutflowTransaction = async (id) => {
    const response = await apiDeleteOutflowTransaction(id);
    setTransactionsContext(response);
  };

  useEffect(() => {
    readOutflowTransactions();
  }, []);

  const memoObjects = {
    outflowTransactions,
    setOutflowTransactions,
    resumeOutflowTransactions,
    outflowDescriptionSuggestions,
    createOutflowTransaction,
    readOutflowTransactions,
    updateOutflowTransaction,
    deleteOutflowTransaction,
  };

  const memoDeps = [
    outflowTransactions,
    setOutflowTransactions,
    resumeOutflowTransactions,
    outflowDescriptionSuggestions,
    createOutflowTransaction,
    readOutflowTransactions,
    updateOutflowTransaction,
    deleteOutflowTransaction,
  ];

  const contextValue = useMemo(
    () => (memoObjects),
    memoDeps,
  );

  return (
    <OutflowContext.Provider value={contextValue}>
      {children}
    </OutflowContext.Provider>
  );
}

export { OutflowProvider, OutflowContext };

OutflowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
