import React, {
  createContext, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import {
  apiCreateInflowTransaction,
  apiReadInflowTransactions,
  apiUpdateInflowTransaction,
  apiDeleteInflowTransaction,
} from '../apiClient';

const InflowContext = createContext();

function InflowProvider({ children }) {
  const [inflowTransactions, setInflowTransactions] = useState([]);
  const [resumeInflowTransactions, setResumeInflowTransactions] = useState({});
  const [inflowDescriptionSuggestions, setInflowDescriptionSuggestions] = useState({});
  const [inflowTotalsMonthly, setInflowTotalsMonthly] = useState({});

  const setTransactionsContext = (response) => {
    setInflowTransactions(response.full || []);
    setResumeInflowTransactions(response.resume || {});
    setInflowDescriptionSuggestions(response.descriptions || {});
    setInflowTotalsMonthly(response.sum_transactions_monthly || {});
  };

  const createInflowTransaction = async (data) => {
    const response = await apiCreateInflowTransaction(data);
    setTransactionsContext(response);
  };

  const readInflowTransactions = async () => {
    const response = await apiReadInflowTransactions();
    setTransactionsContext(response);
  };

  const updateInflowTransaction = async (data) => {
    const response = await apiUpdateInflowTransaction(data);
    setTransactionsContext(response);
  };

  const deleteInflowTransaction = async (id) => {
    const response = await apiDeleteInflowTransaction(id);
    setTransactionsContext(response);
  };

  useEffect(() => {
    readInflowTransactions();
  }, []);

  const memoObjects = {
    inflowTransactions,
    setInflowTransactions,
    resumeInflowTransactions,
    inflowDescriptionSuggestions,
    inflowTotalsMonthly,
    createInflowTransaction,
    readInflowTransactions,
    updateInflowTransaction,
    deleteInflowTransaction,
  };

  const memoDeps = [
    inflowTransactions,
    setInflowTransactions,
    resumeInflowTransactions,
    inflowDescriptionSuggestions,
    inflowTotalsMonthly,
    createInflowTransaction,
    readInflowTransactions,
    updateInflowTransaction,
    deleteInflowTransaction,
  ];

  const contextValue = useMemo(
    () => (memoObjects),
    memoDeps,
  );

  return (
    <InflowContext.Provider value={contextValue}>
      {children}
    </InflowContext.Provider>
  );
}

export { InflowProvider, InflowContext };

InflowProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
