import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { strOnlyTwoDecimals, capitalizeFirstLetter, BRLCurrency } from '../../helpers';

import trashSvg from '../../assets/trash.svg';
import editSvg from '../../assets/edit.svg';
import saveSvg from '../../assets/save.svg';
import cancelSvg from '../../assets/cancel.svg';

function TransactionRow({
  transaction, updateTransaction, removeTransaction, columns,
}) {
  const {
    id,
    date,
    inflow_code: inflowCode,
    outflow_code: outflowCode,
    payment_method: paymentMethod,
    user_name: userName,
  } = transaction;

  const transactionCode = inflowCode || outflowCode || 'Desconhecido';

  const [editMode, setEditMode] = useState(false);
  const [defDescription, setDefDescription] = useState(transaction.description);
  const [description, setDescription] = useState(defDescription);
  const [defAmount, setDefAmount] = useState(transaction.amount);
  const [amount, setAmount] = useState(defAmount);

  const confirmDeleteTransaction = (id_) => {
    const transactionString = `${transactionCode} - ${description} - ${BRLCurrency(defAmount)}`;

    const confirmDelete = window.confirm(`Deseja realmente excluir:\n${transactionString}`);

    if (confirmDelete) return removeTransaction(id_);
    return false;
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    setDescription(capitalizeFirstLetter(value));
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(strOnlyTwoDecimals(value));
  };

  const cancelEdit = () => {
    setDescription(defDescription);
    setAmount(defAmount);
    setEditMode(false);
  };

  const submitEdit = async () => {
    const customAlert = alert;
    const amountBRL = BRLCurrency(amount);

    if (amountBRL === BRLCurrency(0)) {
      return customAlert('Valor/Quantia não pode ser 0.');
    }

    const equalsDescription = description === defDescription;
    const equalsAmount = amountBRL === BRLCurrency(defAmount);

    if ([equalsDescription, equalsAmount].every(Boolean)) {
      return customAlert('Não há mudanças no lançamento.');
    }

    setDefDescription(description);
    setDefAmount(amount);
    setEditMode(false);
    const oldValues = {
      description: transaction.description,
      amount: transaction.amount,
    };

    const transactionData = {
      id,
      description: description.trim(),
      amount: parseFloat(amount).toFixed(2),
      oldValues: JSON.stringify(oldValues),
    };

    return updateTransaction(transactionData);
  };

  const amountSubmit = (e) => {
    if (e.key === 'Enter') {
      submitEdit();
    }
  };

  const rowText = {
    date: (<td key="key_date_text">{date.split('-')[0]}</td>),
    inflow_code: (<td key="key_inflow_code_text">{transactionCode.split(' - ').pop()}</td>),
    description: (<td key="key_description_text">{description}</td>),
    amount: (<td key="key_amount_text">{BRLCurrency(amount)}</td>),
    payment_method: (<td key="key_payment_method_text">{paymentMethod === 'pix' ? 'Pix' : 'Dinheiro'}</td>),
    user_name: (<td key="key_user_name_text">{userName}</td>),
    actions: (
      <td key="key_action_text">
        <div className="actions-row">
          <button type="button" onClick={() => setEditMode(true)}>
            <img width={20} src={editSvg} alt="Edit transction button" />
          </button>
          <button type="button" onClick={() => confirmDeleteTransaction(id)}>
            <img width={20} src={trashSvg} alt="Remove transction button" />
          </button>
        </div>
      </td>
    ),
  };

  const rowEdit = {
    ...rowText,
    description: (
      <td key="key_description_input">
        <label htmlFor="description_input">
          <input
            id="description_input"
            className="edit-transaction"
            type="text"
            onChange={handleDescriptionChange}
            value={description}
          />
        </label>
      </td>
    ),
    amount: (
      <td key="key_amount_input">
        <label htmlFor="amount_input">
          <input
            id="amount_input"
            className="edit-transaction"
            type="number"
            onChange={handleAmountChange}
            value={amount}
            step="0.01"
            min="0.01"
            max="999999.99"
            onKeyDown={amountSubmit}
          />
        </label>
      </td>
    ),
    actions: (
      <td key="key_action_text">
        <div className="actions-row">
          <button type="button" onClick={submitEdit}>
            <img width={20} src={saveSvg} alt="SaveEdit transction button" />
          </button>
          <button type="button" onClick={cancelEdit}>
            <img width={20} src={cancelSvg} alt="Cancel edit transction button" />
          </button>
        </div>
      </td>
    ),
  };

  return (
    <tr key={id} className="transcationRow">
      { editMode ? columns.map((col) => (rowEdit[col])) : columns.map((col) => (rowText[col]))}
    </tr>
  );
}

TransactionRow.propTypes = {
  transaction: PropTypes.oneOfType([PropTypes.object]),
  updateTransactions: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.string]),
}.isRequired;

export default TransactionRow;
