import React, { useState, useContext } from 'react';

import { UserContext } from '../../stores/UserContext';
import { apiUpdateUserPassword } from '../../apiClient';

import './UserProfile.css';

const defaultPair = {
  newPass: '',
  repeatPass: '',
};

function UserProfile() {
  const {
    userLogged, setUserData, logoutUser, appLocation,
  } = useContext(UserContext);

  const [typeNewPass, setTypeNewPass] = useState(false);
  const [passwordPair, setPasswordPair] = useState(defaultPair);
  const [inPassChangeProcess, setInPassChangeProcess] = useState(false);

  const enablePassChange = () => setTypeNewPass(true);

  const alterPass = (e) => {
    const { name, value } = e.target;
    setPasswordPair((prevPassPair) => ({ ...prevPassPair, [name]: value }));
  };

  const cancelChangePass = () => {
    setTypeNewPass(false);
  };

  const submitNewPass = async (e) => {
    e.preventDefault();

    const cpPasswordPair = { ...passwordPair };
    const { newPass, repeatPass } = cpPasswordPair;

    if (newPass !== repeatPass) {
      const customAlert = alert;
      return customAlert('Senhas não são idênticas');
    }

    setInPassChangeProcess(true);
    setPasswordPair(defaultPair);
    setTypeNewPass(false);

    const data = await apiUpdateUserPassword(userLogged.name, newPass);

    setInPassChangeProcess(false);

    if ('msg' in data) {
      const customAlert = alert;
      return customAlert(data.msg);
    }
    return setUserData(data);
  };

  const logout = () => logoutUser();

  const renderInputs = () => (
    <form onSubmit={submitNewPass} className="change-pass-form">
      <div className="change-pass-label-container">
        <label htmlFor="new_pass">
          <span>Nova senha: </span>
          <input
            id="new_pass"
            type="password"
            name="newPass"
            minLength={6}
            value={passwordPair.newPass}
            onChange={alterPass}
            required
          />
        </label>
        <label htmlFor="repeat_pass">
          <span>Repetir senha: </span>
          <input
            id="repeat_pass"
            type="password"
            name="repeatPass"
            minLength={6}
            value={passwordPair.repeatPass}
            onChange={alterPass}
            required
          />
        </label>
      </div>
      <button type="submit">Mudar senha</button>
    </form>
  );

  return (
    <div className={`component-default-container user-profile-container ${appLocation}-container`}>
      <h2>{userLogged.name}</h2>
      { inPassChangeProcess && <h4>Alterando senha...</h4>}
      { typeNewPass && renderInputs() }
      { !typeNewPass && !inPassChangeProcess && <button type="button" onClick={enablePassChange}>Alterar senha</button> }
      { typeNewPass && !inPassChangeProcess && <button type="button" onClick={cancelChangePass}>Cancelar</button> }
      { !typeNewPass && !inPassChangeProcess && <button type="button" onClick={logout}>Sair</button> }
    </div>
  );
}

export default UserProfile;
