/* eslint-disable no-param-reassign */

const strOnlyTwoDecimals = (value) => {
  const [real, centavo] = value.toString().split('.');

  if (centavo) return `${real}.${centavo.slice(0, 2)}`;

  return value;
};

const capitalizeFirstLetter = (str) => {
  const capitalized = (word) => {
    if (word.length > 3) {
      return word[0].toUpperCase() + word.substr(1);
    }
    return word;
  };

  const strArray = str.split(' ');

  return strArray.map(capitalized).join(' ');
};

const BRLCurrency = (valor = 0) => parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

const makePdfColumn = ([k, v]) => {
  const vAmount = v || 0;
  const amountString = BRLCurrency(vAmount);
  const [currency, amount] = amountString.split(amountString[2]);

  return {
    columns: [
      { text: k, margin: [0, 0, -25, 0] },
      { text: currency, alignment: 'center', margin: [-15, 0, -25, 0] },
      { text: amount, alignment: 'right', margin: [-25, 0, 0, 0] },
    ],
  };
};

const formatMonth = (monthDate) => {
  const [month, year] = monthDate.split('/');

  const monthNumber = {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  };

  return `${monthNumber[month]}/${year}`;
};

const updateTotalsMonthlyGroup = (
  categoryGrouped,
  totalsGrouped,
  sumInflowCode,
  inflowCodeGroup,
) => {
  const [inflowCode, sum] = sumInflowCode;
  const item = { [inflowCode.split(' - ')[1]]: sum };

  categoryGrouped[inflowCodeGroup] = { ...categoryGrouped[inflowCodeGroup], ...item };
  totalsGrouped[inflowCodeGroup] += sum;
};

const mapLocationType = {
  entradas: 'inflow',
  saidas: 'outflow',
};

export {
  strOnlyTwoDecimals,
  capitalizeFirstLetter,
  BRLCurrency,
  makePdfColumn,
  formatMonth,
  updateTotalsMonthlyGroup,
  mapLocationType,
};
