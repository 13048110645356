import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { UserContext } from '../../stores/UserContext';
import InflowTotalsFromMonth from './inflowTotalsFromMonth';
import OutflowTotalsFromMonth from './outflowTotalsFromMonth';

import './TotalsMonthly.css';

function convertStringToDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(Number(year), Number(month) - 1, Number(day));
}

const twoMonthAgo = () => {
  const hoje = new Date();

  hoje.setMonth(hoje.getMonth() - 1);
  hoje.setDate(1);
  hoje.setHours(0, 0, 0, 0);

  return hoje;
};

const groupTransactionByMonthByCode = (inflowTransactions, appLocation) => {
  const inflowByMonthByCode = inflowTransactions.reduce((acc, item) => {
    const transactionDate = item.date.split('-')[0];
    const transactionMonthYear = transactionDate.slice(3);

    if (convertStringToDate(transactionDate) < twoMonthAgo()) return acc;

    const inflowCode = item[`${appLocation}_code`];

    const tempInfo = {
      [transactionMonthYear]: {
        [inflowCode]: [
          item,
        ],
      },
    };

    if (!acc[transactionMonthYear]) {
      acc[transactionMonthYear] = tempInfo[transactionMonthYear];
    } else {
      const accInflowCode = acc[transactionMonthYear][inflowCode];

      if (!accInflowCode) {
        acc[transactionMonthYear][inflowCode] = tempInfo[transactionMonthYear][inflowCode];
      } else {
        accInflowCode.push(item);
      }
    }
    return acc;
  }, {});

  return inflowByMonthByCode;
};

function TotalsMonthly({ inflowTransactions }) {
  const { appLocation } = useContext(UserContext);

  const transactionByMonthByCode = groupTransactionByMonthByCode(inflowTransactions, appLocation);
  const transactionByMonthByCodeEntries = Object.entries(transactionByMonthByCode);

  const transactionsTotalsMapLocation = {
    inflow: (totalsMonth) => InflowTotalsFromMonth(totalsMonth),
    outflow: (totalsMonth) => OutflowTotalsFromMonth(totalsMonth),
  };

  return (
    <div className={`component-default-container ${appLocation}-container totals-monthly-container`}>
      {transactionByMonthByCodeEntries.map((totalsMonth, index, array) => (
        <React.Fragment key={totalsMonth[0]}>
          {transactionsTotalsMapLocation[appLocation](totalsMonth)}
          {index < array.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </div>
  );
}

TotalsMonthly.propTypes = {
  inflowTotalsMonthly: PropTypes.object,
}.isRequired;

export default TotalsMonthly;
