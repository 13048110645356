import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../stores/UserContext';

import Login from '../components/Login';

function LoginPage() {
  const { userLogged } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (userLogged) {
      return navigate('/entradas');
    }
    return navigate('/');
  }, [userLogged]);

  return (
    <Login />
  );
}

export default LoginPage;
