const entradas = {
  '1.01.01 - Dizimo dos Membros': 'Dízimo dos Membros',
  '1.01.02 - Dizimo dos Congregados': 'Dízimo dos Congregados',
  '1.02.01 - Oferta de Culto': 'Oferta de Culto',
  '1.02.16 - Oferta de Construção': 'Oferta de Construção',
  '1.02.14 - Oferta de Culto para Missões': 'Oferta de Missões',
  '1.02.03 - Ofertas da EBD': 'Oferta de EBD',
  '1.02.04 - Oferta dos Departamentos e Ministérios': 'Oferta de Ministério',
  '1.01.07 - Dízimo dos Clérigos (100% conforme FIW 2022/2023)': 'Dízimo dos Clérigos',
  '1.06.02 - PATRIMONIO - Venda de Bens Imóveis': 'Venda Imóvel',
  '1.02.02 - Ofertas Especiais': 'Ofertas Especiais',
  '1.07.01 - Imposto de Renda Retido - Clérigos': 'Imposto de Renda Clérigos',
};

export default entradas;
