import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../stores/UserContext';
import { mapLocationType } from '../../helpers';

import './ChangePage.css';

function ChangePage() {
  const { appLocation, setAppLocation } = useContext(UserContext);

  const navigate = useNavigate();

  const changeLocation = async (path) => {
    setAppLocation(mapLocationType[path]);
    navigate(`/${path}`);
  };

  return (
    <div className={`component-default-container change-page-container ${appLocation}-container`}>
      <button type="button" onClick={() => changeLocation('entradas')}>
        <h2 className={appLocation === 'inflow' ? 'inflow-selected' : 'inflow-not-selected'}>Entradas</h2>
      </button>
      <button type="button" onClick={() => changeLocation('saidas')}>
        <h2 className={appLocation === 'outflow' ? 'outflow-selected' : 'outflow-not-selected'}>Saídas</h2>
      </button>
    </div>
  );
}

export default ChangePage;
