const saidas = {
  '2.01 - DESPESAS ADMINISTRATIVAS': [
    '2.01.01 - DESPESAS ADMINISTRATIVAS - Água',
    '2.01.02 - DESPESAS ADMINISTRATIVAS - Luz/Energia',
    '2.01.03 - DESPESAS ADMINISTRATIVAS - Telefone Fixo',
    '2.01.04 - DESPESAS ADMINISTRATIVAS - Telefone Celular',
    '2.01.05 - DESPESAS ADMINISTRATIVAS - Combustível e Lubrificantes',
    '2.01.06 - DESPESAS ADMINISTRATIVAS - Material de Escritório',
    '2.01.07 - DESPESAS ADMINISTRATIVAS - Internet e TV (cabo ou satélite)',
    '2.01.08 - DESPESAS ADMINISTRATIVAS - Gás (botija ou encanado)',
    '2.01.09 - DESPESAS ADMINISTRATIVAS - Transporte (onibus, van, taxi, fretes, carretos, etc)',
    '2.01.10 - DESPESAS ADMINISTRATIVAS - Honorários e Prestação de Serviços',
    '2.01.11 - DESPESAS ADMINISTRATIVAS - Impressos',
    '2.01.15 - DESPESAS ADMINISTRATIVAS - Ajuda de Custo Excedente para Clérigos',
    '2.01.16 - DESPESAS ADMINISTRATIVAS - Anuênio (verba de representação) para Clérigos',
    '2.01.17 - DESPESAS ADMINISTRATIVAS - Alimentação (lanches e refeições)',
    '2.01.18 - DESPESAS ADMINISTRATIVAS - Consumíveis (descartáveis, copos, talheres, calices)',
    '2.01.99 - DESPESAS ADMINISTRATIVAS - Outras',
  ],
  '2.02 - DESPESAS PASTORAIS': [
    '2.02.01 - DESPESAS PASTORAIS - Subsídios (prebendas)',
    '2.02.02 - DESPESAS PASTORAIS - Viagens/Expediente',
    '2.02.03 - DESPESAS PASTORAIS - CAW',
    '2.02.04 - DESPESAS PASTORAIS - INSS',
    '2.02.05 - DESPESAS PASTORAIS - Plano de Saúde',
    '2.02.06 - DESPESAS PASTORAIS - Seguros Diversos',
    '2.02.07 - DESPESAS PASTORAIS - Abono Natalino',
    '2.02.08 - DESPESAS PASTORAIS - Previdência Privada (outra além do CAW)',
    '2.02.09 - DESPESAS PASTORAIS - Adiantamento de Subsidio a Clérigos',
    '2.02.99 - DESPESAS PASTORAIS - Outras',
  ],
  '2.03 - AQUISIÇÕES': [
    '2.03.01 - AQUISIÇÕES - Equipamentos de Som',
    '2.03.02 - AQUISIÇÕES - Equipamentos Eletro-Eletrônicos (Geladeiras, Frizers, Tvs)',
    '2.03.03 - AQUISIÇÕES - Mobiliário (cadeiras, mesas, armários)',
    '2.03.04 - AQUISIÇÕES - Veículos',
    '2.03.05 - AQUISIÇÕES - Imóveis (predios, residencias, salas)',
    '2.03.07 - AQUISIÇÕES - Acampamentos',
    '2.03.08 - AQUISIÇÕES - Terrenos',
    '2.03.99 - AQUISIÇÕES - Outras',
  ],
  '2.04 - ALUGUÉIS': [
    '2.04.05 - ALUGUÉIS - Casas Pastorais',
    '2.04.99 - ALUGUÉIS - Outros',
  ],
  '2.05 - CONSERVAÇÃO': [
    '2.05.01 - CONSERVAÇÃO - Material de Limpeza e Higiene',
    '2.05.02 - CONSERVAÇÃO - Manutenção de Equipamentos de Som',
    '2.05.03 - CONSERVAÇÃO - Manutenção de Equipamentos (exceto eq. som)',
    '2.05.04 - CONSERVAÇÃO - Manutenção de Instalações (pequenos reparos)',
    '2.05.05 - CONSERVAÇÃO - Manutenção de Veículos',
    '2.05.99 - CONSERVAÇÃO - Outros',
  ],
  '2.06 - DEPARTAMENTOS': [
    '2.06.01 - DEPARTAMENTOS - Crianças',
    '2.06.02 - DEPARTAMENTOS - Pré-adolescentes',
    '2.06.03 - DEPARTAMENTOS - Adolescentes',
    '2.06.04 - DEPARTAMENTOS - Jovens',
    '2.06.05 - DEPARTAMENTOS - Adultos',
    '2.06.06 - DEPARTAMENTOS - Homens',
    '2.06.07 - DEPARTAMENTOS - Mulheres',
    '2.06.08 - DEPARTAMENTOS - Terceira Idade',
    '2.06.99 - DEPARTAMENTOS - Outros',
  ],
  '2.07 - AÇÃO SOCIAL': [
    '2.07.01 - AÇÃO SOCIAL - Ajuda Financeira',
    '2.07.02 - AÇÃO SOCIAL - Medicamentos e Roupas',
    '2.07.03 - AÇÃO SOCIAL - Distribuição de Alimentos',
    '2.07.04 - AÇÃO SOCIAL - Transporte',
    '2.07.99 - AÇÃO SOCIAL - Outras',
  ],
  '2.08 - EBD - Escola Bíblica Dominical': [
    '2.08.01 - EBD - Material Didático',
    '2.08.02 - EBD - Revistas do CPIMW',
    '2.08.03 - EBD - Revistas (outras publicadoras)',
    '2.08.99 - EBD - Outras',
  ],
  '2.09 - EVANGELISMO': [
    '2.09.01 - EVANGELISMO - Distribuição de Literatura (Revistas, Jornais, Folhetos, Bíblias)',
    '2.09.02 - EVANGELISMO - Cultos, Campanhas e Cruzadas Evangelisticas',
    '2.09.03 - EVANGELISMO - Despesas com Obreiros e Preletores',
    '2.09.04 - EVANGELISMO - Divulgação e Publicidade',
    '2.09.05 - EVANGELISMO - Pontos de Pregação',
    '2.09.06 - EVANGELISMO - Visitações',
    '2.09.99 - EVANGELISMO - Outros',
  ],
  '2.10 - CONSTRUÇÕES': [
    '2.10.01 - CONSTRUÇÕES - Mão de Obra',
    '2.10.02 - CONSTRUÇÕES - Materiais',
    '2.10.03 - CONSTRUÇÕES - Reformas em Geral',
    '2.10.99 - CONSTRUÇÕES - Outros',
  ],
  '2.11 - DESPESAS FINANCEIRAS': [
    '2.11.01 - DESPESAS FINANCEIRAS - Tarifas Bancárias',
    '2.11.02 - DESPESAS FINANCEIRAS - Juros',
    '2.11.03 - DESPESAS FINANCEIRAS - Financiamentos',
    '2.11.05 - DESPESAS FINANCEIRAS - Taxa Operacional Uso Máquina Cartão',
    '2.11.99 - DESPESAS FINANCEIRAS - Outras',
  ],
  '2.12 - RH - RECURSOS HUMANOS': [
    '2.12.01 - RH - RECURSOS HUMANOS - Salários dos Funcionarios',
    '2.12.02 - RH - RECURSOS HUMANOS - 13o Salário',
    '2.12.03 - RH - RECURSOS HUMANOS - Férias',
    '2.12.04 - RH - RECURSOS HUMANOS - Prêmios e Gratificações',
    '2.12.05 - RH - RECURSOS HUMANOS - Recisões',
    '2.12.06 - RH - RECURSOS HUMANOS - Pensões Alimentícias',
    '2.12.07 - RH - RECURSOS HUMANOS - Contribuições Sindicais',
    '2.12.08 - RH - RECURSOS HUMANOS - Mensalidades Sindicais',
    '2.12.09 - RH - RECURSOS HUMANOS - Fundo de Garantia - FGTS',
    '2.12.10 - RH - RECURSOS HUMANOS - Imposto de Renda Retido na Fonte - IRRF',
    '2.12.11 - RH - RECURSOS HUMANOS - INSS/GPS',
    '2.12.12 - RH - RECURSOS HUMANOS - Vale Transporte',
    '2.12.13 - RH - RECURSOS HUMANOS - Vale Alimentação (cesta basica)',
    '2.12.14 - RH - RECURSOS HUMANOS - Assistência Médica e Social',
    '2.12.15 - RH - RECURSOS HUMANOS - Seguro de Vida dos Empregados',
    '2.12.16 - RH - RECURSOS HUMANOS - PIS sobre folha',
    '2.12.18 - RH - RECURSOS HUMANOS - Vale Refeição (ticket alimentação)',
    '2.12.99 - RH - RECURSOS HUMANOS - Outros',
  ],
  '2.13 - EVENTOS': [
    '2.13.01 - EVENTOS - Campanhas e Gincanas',
    '2.13.02 - EVENTOS - Congressos Wesleyanos',
    '2.13.03 - EVENTOS - Congressos Outros',
    '2.13.04 - EVENTOS - Concílios Gerais',
    '2.13.05 - EVENTOS - Concílios Regionais',
    '2.13.06 - EVENTOS - Convenções',
    '2.13.07 - EVENTOS - Encontros',
    '2.13.08 - EVENTOS - Retiros',
    '2.13.09 - EVENTOS - Comemorações e Festividades',
    '2.13.99 - EVENTOS - Outros',
  ],
  '2.15 - IMPOSTOS E TAXAS': [
    '2.15.02 - IMPOSTOS E TAXAS - IPTU',
    '2.15.03 - IMPOSTOS E TAXAS - IPVA',
    '2.15.04 - IMPOSTOS E TAXAS - ITBI',
    '2.15.05 - IMPOSTOS E TAXAS - Multas',
    '2.15.06 - IMPOSTOS E TAXAS - Despesas Legais',
    '2.15.07 - IMPOSTOS E TAXAS - IOF',
    '2.15.96 - IMPOSTOS E TAXAS - Outros Tributos Municipais',
    '2.15.97 - IMPOSTOS E TAXAS - Outros Tributos Estaduais',
    '2.15.98 - IMPOSTOS E TAXAS - Outros Tributos Federais',
    '2.15.99 - IMPOSTOS E TAXAS - Outros',
  ],
  '2.16 - INSTITUIÇÕES': [
    '2.16.01 - INSTITUIÇÕES - Acampamentos',
    '2.16.02 - INSTITUIÇÕES - CEFORTE',
    '2.16.03 - INSTITUIÇÕES - Casa Bom Pastor',
    '2.16.04 - INSTITUIÇÕES - AGEMIW',
    '2.16.05 - INSTITUIÇÕES - AWAS - Associação Wesleyana de Assistência Social (e afins)',
    '2.16.06 - INSTITUIÇÕES - Outras Instituições MIssionárias',
    '2.16.07 - INSTITUIÇÕES - FUEMIS - Fundo de Expansão Missionária',
    '2.16.08 - INSTITUIÇÕES - Outras I.M.W.',
    '2.16.09 - INSTITUIÇÕES - CP - Jornal Voz Wesleyana',
    '2.16.10 - INSTITUIÇÕES - Outras Igrejas ou Denominações',
    '2.16.12 - INSTITUIÇÕES - ESCOMIW',
    '2.16.99 - INSTITUIÇÕES - Outras Instituições',
  ],
  '2.17 - MINISTÉRIOS': [
    '2.17.01 - MINISTÉRIOS - Louvor',
    '2.17.02 - MINISTÉRIOS - Intercessão',
    '2.17.03 - MINISTÉRIOS - Casais',
    '2.17.04 - MINISTÉRIOS - Família',
    '2.17.05 - MINISTÉRIOS - Diplomacia',
    '2.17.06 - MINISTÉRIOS - Teatro',
    '2.17.07 - MINISTÉRIOS - Coral',
    '2.17.08 - MINISTÉRIOS - Consolidação de Novos Convertidos',
    '2.17.09 - MINISTÉRIOS - GCEUs',
    '2.17.10 - MINISTÉRIOS - Santa Ceia',
    '2.17.11 - MINISTÉRIOS - Missões (Conselho Missionário)',
    '2.17.12 - MINISTÉRIOS - Dança',
    '2.17.13 - MINISTÉRIOS - Ação Social',
  ],
  '2.18 - SECRETARIAS': [
    '2.18.01 - SECRETARIAS - SRF - Regional de Finanças (COTA ÚNICA)',
    '2.18.02 - SECRETARIAS - SRM - Regional de Missões',
    '2.18.03 - SECRETARIAS - SRA - Regional de Administração',
    '2.18.04 - SECRETARIAS - SREC - Regional de Educação Cristã',
    '2.18.05 - SECRETARIAS - SRAS - Regional de Ação Social',
    '2.18.06 - SECRETARIAS - SGF - Geral de Finanças',
    '2.18.07 - SECRETARIAS - SGM - Geral de Missões (Missões Estrangeiras)',
    '2.18.08 - SECRETARIAS - SGA - Geral de Administração',
    '2.18.09 - SECRETARIAS - SGEC - Geral de Educação Cristã',
    '2.18.10 - SECRETARIAS - SGAS - Geral de Ação Social',
    '2.18.11 - SECRETARIAS - SRF/SRA - IR - Repasse Imposto Retido de Clérigos',
    '2.18.12 - SECRETARIAS - SRF/SRA - IR - Repasse Imposto Retido de Terceiros',
    '2.18.13 - SECRETARIAS - AGEMIW - PAM - Plano de Adoção Missionária (Missões)',
    '2.18.16 - SECRETARIAS - SRM - Projetos da Secretaria',
    '2.18.17 - SECRETARIAS - SRM - Conferencia Regional',
    '2.18.18 - SECRETARIAS - SRM - Conferencia Distrital',
    '2.18.19 - SECRETARIAS - SGAS - 25% do FIW dos Clérigos',
    '2.18.22 - SECRETARIAS - SRF - Oferta de Gratidão',
    '2.18.23 - SECRETARIAS - SRA/FIW 100%',
  ],
  '2.19 - EMPRÉSTIMOS': [
    '2.19.01 - EMPRÉSTIMOS - SGF/SRF - Secretaria de Finanças',
    '2.19.02 - EMPRÉSTIMOS - FIW - Fundo Imobiliário Wesleyano',
    '2.19.03 - EMPRÉSTIMOS - Outras Secretarias',
    '2.19.04 - EMPRÉSTIMOS - Outras Igrejas',
    '2.19.06 - EMPRÉSTIMOS - Bancos - Empréstimos Contratados',
    '2.19.99 - EMPRÉSTIMOS - Outros',
  ],
  '2.20 - DISTRITOS': [
    '2.20.01 - DISTRITOS - Cota Distrital',
    '2.20.02 - DISTRITOS - Ofertas Distritais',
    '2.20.03 - DISTRITOS - Dízimo dos Clérigos (conforme orientação da região ou distrito)',
    '2.20.99 - DISTRITOS - Outras despesas',
  ],
};

export default saidas;
