import React from 'react';
import PropTypes from 'prop-types';
import { strOnlyTwoDecimals } from '../../../helpers';

function InputAmount({ amount, handleInputChange }) {
  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(name, strOnlyTwoDecimals(value));
  };

  return (
    <label htmlFor="form-amount">
      <span>Valor:</span>
      <input
        id="form-amount"
        type="number"
        step="0.01"
        min="0.01"
        max="999999.99"
        name="amount"
        value={amount}
        onChange={handleAmountChange}
        placeholder="0.00"
        required
      />
    </label>
  );
}

InputAmount.propTypes = {
  date: PropTypes.string,
  handleInputChange: PropTypes.func,
}.isRequired;

export default InputAmount;
