import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../stores/UserContext';

const env = process.env.REACT_APP_TABLE_ENV;

function ErrorBoundary() {
  if (env !== 'qa') {
    const { logoutUser, userLogged } = useContext(UserContext);
    const navigate = useNavigate();
    logoutUser();
    window.location.reload();

    useEffect(() => {
      if (!userLogged) {
        return navigate('/');
      }
      return null;
    }, [userLogged]);
  }

  return <div />;
}

export default ErrorBoundary;
