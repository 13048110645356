import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { UserContext } from '../../stores/UserContext';

import TransactionRow from './TransactionRow';

import './TransactionsTable.css';

const columnsRename = {
  date: 'Data',
  inflow_code: 'Lançamento',
  description: 'Descrição',
  amount: 'Valor',
  payment_method: 'Método',
  actions: 'Ação',
  user_name: 'Autor',
};

const columnsOrder = Object.keys(columnsRename);

const columnsLvl = {
  2: ['date', 'inflow_code', 'amount'],
};
columnsLvl[3] = [...columnsLvl[2], 'description', 'payment_method'];
columnsLvl[4] = [...columnsLvl[3], 'actions'];
columnsLvl[5] = [...columnsLvl[4], 'user_name'];

function TransactionsTable({ transactions, updateTransaction, removeTransaction }) {
  const { userLogged, appLocation } = useContext(UserContext);

  const userLvl = userLogged ? userLogged.scopes.inflow : 3;

  const userColumns = [...columnsLvl[userLvl]];
  userColumns.sort((a, b) => (
    columnsOrder.indexOf(a) - columnsOrder.indexOf(b)
  ));

  const generateColumns = () => {
    if (transactions.length === 0) {
      return (<th> </th>);
    }

    return userColumns.map((col) => (<th key={`key-${col}`}>{columnsRename[col]}</th>));
  };

  if (transactions.length === 0) {
    return (<div />);
  }

  return (
    <div className="table-container">
      <table className={`${appLocation}-table`}>
        <thead>
          <tr>
            {generateColumns()}
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction) => (
            <TransactionRow
              key={transaction.id}
              transaction={transaction}
              columns={userColumns}
              updateTransaction={updateTransaction}
              removeTransaction={removeTransaction}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

TransactionsTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  updateTransactions: PropTypes.func,
}.isRequired;

export default TransactionsTable;
