import React from 'react';
import { formatMonth, BRLCurrency, updateTotalsMonthlyGroup } from '../../helpers';

const categoriesFromSRFQuota = ['1.01.01 - Dizimo dos Membros', '1.01.02 - Dizimo dos Congregados', '1.02.01 - Oferta de Culto'];
const strInitialDizimos = '1.01';
const strInitialOfertas = '1.02';

const categoryTexts = {
  total: 'Total',
  cota_srf: 'Cota Secretaria 19%',
  dizimos: 'Dízimos',
  ofertas: 'Ofertas',
  outros: 'Outros',
};

function InflowTotalsFromMonth([month, data]) {
  const totalsGrouped = {
    total: 0,
    cota_srf: 0,
    dizimos: 0,
    ofertas: 0,
    outros: 0,
  };

  const categoryGrouped = {
    total: {},
    cota_srf: {},
    dizimos: {},
    ofertas: {},
    outros: {},
  };

  const detailedInfoFromCode = (category) => (
    <div className="hidden-info">
      {Object.entries(categoryGrouped[category]).map(([categoryName, sum]) => (
        <p key={categoryName}>
          {`${categoryName}: `}
          <strong>{BRLCurrency(sum)}</strong>
        </p>
      ))}
    </div>
  );

  const totalsByInflowCode = Object.entries(data).reduce((acc, [inflowCode, transactions]) => {
    const totalFromCategory = transactions.reduce((sumTransac, t) => (sumTransac + t.amount), 0);

    acc[inflowCode] = totalFromCategory;

    return acc;
  }, {});

  Object.entries(totalsByInflowCode).forEach((sumInflowCode) => {
    const [inflowCode] = sumInflowCode;
    const inflowCodePrefix = inflowCode.slice(0, 4);

    const inflowCodePrefixMap = {
      [strInitialDizimos]: () => updateTotalsMonthlyGroup(categoryGrouped, totalsGrouped, sumInflowCode, 'dizimos'),
      [strInitialOfertas]: () => updateTotalsMonthlyGroup(categoryGrouped, totalsGrouped, sumInflowCode, 'ofertas'),
      default: () => updateTotalsMonthlyGroup(categoryGrouped, totalsGrouped, sumInflowCode, 'outros'),
    };
    (inflowCodePrefixMap[inflowCodePrefix] || inflowCodePrefixMap.default)();

    if (categoriesFromSRFQuota.includes(inflowCode)) {
      updateTotalsMonthlyGroup(categoryGrouped, totalsGrouped, sumInflowCode, 'cota_srf');
    }
    updateTotalsMonthlyGroup(categoryGrouped, totalsGrouped, sumInflowCode, 'total');
  });

  totalsGrouped.cota_srf *= 0.19;

  return (
    <div>
      <h4>{`${formatMonth(month)}:`}</h4>
      <div className="total-categories">
        {Object.entries(totalsGrouped).map(([inflowCode, sum]) => (
          <div key={inflowCode} className="info-category">
            <p className="resume-category">
              {`${categoryTexts[inflowCode]}: `}
              <strong>{BRLCurrency(sum)}</strong>
            </p>
            { detailedInfoFromCode(inflowCode) }
          </div>
        ))}
      </div>
    </div>
  );
}

export default InflowTotalsFromMonth;
