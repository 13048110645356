import { genericApiRequest } from './helpers';

async function readUser(userData) {
  const payload = {
    method: 'get',
    data: {
      user: {
        name: userData.name,
        password: userData.password,
      },
    },
  };

  return genericApiRequest(payload);
}

async function updateUserPassword(userName, newPassord) {
  const payload = {
    method: 'put',
    data: {
      user: {
        password: {
          name: userName,
          new_value: newPassord,
        },
      },
    },
  };

  return genericApiRequest(payload);
}

export { readUser, updateUserPassword };
