import React from 'react';
import PropTypes from 'prop-types';

function InputCashAccount({ cashAccount, handleInputChange }) {
  const handleCashAccountChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(name, value);
  };

  return (
    <label htmlFor="form_cash_account">
      <span>Caixa:</span>
      <select
        id="form_cash_account"
        name="cash_account"
        value={cashAccount}
        onChange={handleCashAccountChange}
      >
        <option key="principal" value="principal">Principal</option>
        <option key="obras" value="obras">Obras</option>
      </select>
    </label>
  );
}

InputCashAccount.propTypes = {
  cashAccount: PropTypes.string,
  handleInputChange: PropTypes.func,
}.isRequired;

export default InputCashAccount;
