import React, { useState } from 'react';
import PropTypes from 'prop-types';

function InputTransactionCode({
  appLocation, transactionCodeSelected, transactionCodes, handleInputChange,
}) {
  const selectId = 'form_transaction_code';
  const selectName = 'transaction_code';
  const [suggestions, setSugestions] = useState([]);

  const handleTransactionCodeChange = (e) => {
    const { value } = e.target;
    handleInputChange(selectName, value);
  };

  const entradasSelect = () => (
    <select
      id={selectId}
      name={selectName}
      onChange={handleTransactionCodeChange}
      value={transactionCodeSelected}
    >
      {Object.entries(transactionCodes).map(([title, value]) => (
        <option key={title} value={title}>
          {value}
        </option>
      ))}
    </select>
  );

  const handleInputChange2 = (e) => {
    const { value } = e.target;
    if (value) {
      const filteredSuggestions = transactionCodes.filter(
        (suggestion) => suggestion.toLowerCase().includes(value.toLowerCase()),
      );
      setSugestions(filteredSuggestions);
    } else {
      setSugestions([]);
    }
    handleInputChange(selectName, value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSugestions([]);
    handleInputChange(selectName, suggestion);
  };

  const saidasSelect = () => (
    <div className="select_outflow_transaction_code">
      <input
        id="form_description"
        type="text"
        name="description"
        value={transactionCodeSelected}
        onChange={handleInputChange2}
        placeholder={transactionCodes[0]}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setSugestions([]);
        }}
        required
      />
      {suggestions.length > 0 && (
        <ul role="menu" className="suggestions-list">
          {suggestions.map((suggestion) => (
            <li
              key={`key-${suggestion}`}
              role="menuitem"
              aria-labelledby="menu"
              tabIndex={0}
              onClick={() => handleSuggestionClick(suggestion)}
              aria-hidden="true"
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}

    </div>
  );

  return (
    <label htmlFor="form_transaction_code">
      <span>Tipo:</span>
      {appLocation === 'inflow' ? entradasSelect() : saidasSelect()}
    </label>
  );
}

InputTransactionCode.propTypes = {
  appLocation: PropTypes.string,
  handleInputChange: PropTypes.func,
  transactionCodeSelected: PropTypes.string,
  transactionCodes: PropTypes.object,
}.isRequired;

export default InputTransactionCode;
