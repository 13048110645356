import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { InflowProvider } from './stores/InflowContext';
import { OutflowProvider } from './stores/OutflowContext';
import { UserProvider } from './stores/UserContext';

import LoginPage from './pages/Login';
import InflowPage from './pages/Inflow';
import OutflowPage from './pages/Outflow';
import ErrorBoundary from './components/ErrorBoundary';

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/entradas',
    element: <InflowPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/saidas',
    element: <OutflowPage />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <h1>Página nao existente</h1>,
  },
]);

function App() {
  return (
    <UserProvider>
      <InflowProvider>
        <OutflowProvider>
          <RouterProvider router={router} />
        </OutflowProvider>
      </InflowProvider>
    </UserProvider>
  );
}

export default App;
