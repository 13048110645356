import { genericApiRequest } from './helpers';

const emptyResponse = {
  full: [],
  resume: {},
  descriptions: {},
  sum_transactions_monthly: {},
};

async function createInflowTransaction(transactionData) {
  const payload = {
    method: 'post',
    data: {
      ...transactionData,
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function readInflowTransactions() {
  const method = 'get';
  const payload = {
    method,
    data: {
      inflow: {},
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function updateInflowTransaction(inflow) {
  const payload = {
    method: 'put',
    data: {
      inflow,
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

async function deleteInflowTransaction(inflowId) {
  const payload = {
    method: 'delete',
    data: {
      inflow: {
        id: inflowId,
      },
    },
  };

  return genericApiRequest(payload, emptyResponse);
}

export {
  createInflowTransaction,
  readInflowTransactions,
  updateInflowTransaction,
  deleteInflowTransaction,
};
