import React from 'react';
import PropTypes from 'prop-types';

function InputDate({ date, handleInputChange }) {
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(name, value);
  };

  return (
    <label htmlFor="form_date">
      <span>Data:</span>
      <input
        id="form_date"
        type="date"
        name="date"
        value={date}
        onChange={handleDateChange}
        required
      />
    </label>
  );
}

InputDate.propTypes = {
  date: PropTypes.string,
  handleInputChange: PropTypes.func,
}.isRequired;

export default InputDate;
