import React from 'react';
import PropTypes from 'prop-types';

function InputPaymentMethod({ paymentMethod, handleInputChange }) {
  const handlePaymentMethodChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(name, value);
  };

  return (
    <label htmlFor="form_payment_method_cash">
      <span>Método:</span>
      <select
        id="form_payment_method_cash"
        name="payment_method"
        value={paymentMethod}
        onChange={handlePaymentMethodChange}
      >
        <option key="cash" value="cash">
          Dinheiro
        </option>
        <option key="pix" value="pix">
          Pix
        </option>
      </select>
    </label>
  );
}

InputPaymentMethod.propTypes = {
  date: PropTypes.string,
  handleInputChange: PropTypes.func,
}.isRequired;

export default InputPaymentMethod;
