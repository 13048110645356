import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from '../../../helpers';

function InputDescription({ description, handleInputChange, suggestions = [] }) {
  const [suggestionOptions, setsuggestionOptions] = useState(suggestions);

  const handleDescriptionChange = (e) => {
    const { name, value } = e.target;
    const filteredOptions = suggestions.filter((option) => option
      .toLowerCase().includes(value.toLowerCase()));

    const valueCapitalized = capitalizeFirstLetter(value);

    if (filteredOptions.length > 0) {
      setsuggestionOptions([valueCapitalized, ...filteredOptions]);
    } else {
      setsuggestionOptions(filteredOptions);
    }
    handleInputChange(name, capitalizeFirstLetter(valueCapitalized));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setsuggestionOptions([]);
    }
  };

  const getOptionSelected = (e) => {
    e.target.value = e.target.textContent;

    handleDescriptionChange(e);
    setsuggestionOptions([]);
  };

  return (
    <label htmlFor="form_description" id="autoComplete">
      <span>Descrição:</span>
      <input
        id="form_description"
        type="text"
        name="description"
        value={description}
        onChange={handleDescriptionChange}
        onKeyDown={handleKeyDown}
        placeholder="Dizimista / Descrição"
        required
      />
      {description.length > 0 && suggestionOptions.length > 0 && (
        <section>
          {suggestionOptions.map((sugOpt) => (
            <button key={sugOpt} type="button" onClick={getOptionSelected} name="description">
              {sugOpt}
            </button>
          ))}
        </section>
      )}
    </label>
  );
}

InputDescription.propTypes = {
  description: PropTypes.string,
  handleInputChange: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.string),
}.isRequired;

export default InputDescription;
