import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { UserContext } from '../../stores/UserContext';

import InputDate from './inputs/InputDate';
import InputTransactionCode from './inputs/InputTransactionCode';
import InputDescription from './inputs/InputDescription';
import InputPaymentMethod from './inputs/InputPaymentMethod';
import InputAmount from './inputs/InputAmount';
import InputCashAccount from './inputs/InputCashAccount';

import './TransactionForm.css';

const defaultFormValues = (transactionCodeDefault) => {
  const today = new Date();
  const date = today.toLocaleDateString('pt-BR');

  return {
    date: date.split('/').reverse().join('-'),
    transaction_code: transactionCodeDefault,
    description: '',
    amount: '',
    payment_method: 'cash',
    cash_account: 'principal',
  };
};

function TransactionForm({
  transactionCodes = {}, transactionCodeDefault, descriptionSuggestions = {}, createTransaction,
}) {
  const { userLogged, appLocation } = useContext(UserContext);
  const [transaction, setTransaction] = useState(defaultFormValues(transactionCodeDefault));
  const [notHideDescription, setNotHideDescription] = useState(true);

  const submitForm = async (e) => {
    e.preventDefault();
    document.activeElement.blur();

    const transactionData = { ...transaction };

    const today = new Date();
    const formattedDate = today.toLocaleTimeString('pt-BR');
    transactionData.date += `T${formattedDate}.0001Z`;
    transactionData.amount = parseFloat(transactionData.amount).toFixed(2);
    transactionData.description = transactionData.description.trim();
    transactionData.user_name = userLogged.name;

    setTransaction(defaultFormValues(transactionCodeDefault));
    setNotHideDescription(true);

    const transactionCodeType = `${appLocation}_code`;
    transactionData[transactionCodeType] = transactionData.transaction_code;

    delete transactionData.transaction_code;

    return createTransaction({ [appLocation]: transactionData });
  };

  const handleInputChange = (name, value) => {
    setTransaction((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const hideDescription = (inflowCode) => {
    const inflowCodesWithAutoDescription = ['1.02.01 - Oferta de Culto', '1.02.16 - Oferta de Construção', '1.02.14 - Oferta de Culto para Missões', '1.02.03 - Ofertas da EBD'];
    let descriptionValue = '';

    if (inflowCodesWithAutoDescription.includes(inflowCode)) {
      setNotHideDescription(false);
      descriptionValue = transactionCodes[inflowCode];
    } else {
      setNotHideDescription(true);
      descriptionValue = '';
    }
    handleInputChange('description', descriptionValue);
  };

  useEffect(() => {
    hideDescription(transaction.transaction_code);
  }, [transaction.transaction_code]);

  return (
    <form
      onSubmit={submitForm}
      className={`component-default-container transaction-form ${appLocation}-container`}
    >
      <InputDate
        date={transaction.date}
        handleInputChange={handleInputChange}
      />

      <InputTransactionCode
        appLocation={appLocation}
        transactionCodeSelected={transaction.transaction_code}
        handleInputChange={handleInputChange}
        transactionCodes={transactionCodes}
      />

      { notHideDescription
      && (
        <InputDescription
          description={transaction.description}
          handleInputChange={handleInputChange}
          suggestions={descriptionSuggestions[transaction.transaction_code] || []}
        />
      )}

      { appLocation === 'inflow'
        && (
          <InputPaymentMethod
            paymentMethod={transaction.payment_method}
            handleInputChange={handleInputChange}
          />
        )}

      <InputAmount
        amount={transaction.amount}
        handleInputChange={handleInputChange}
      />

      <InputCashAccount
        cashAccount={transaction.cash_account}
        handleInputChange={handleInputChange}
      />

      <button type="submit">Enviar</button>
    </form>
  );
}

TransactionForm.propTypes = {
  transactionCodes: PropTypes.arrayOf(PropTypes.object),
}.isRequired;

export default TransactionForm;
